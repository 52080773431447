// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCTaWlLBr2pbuyZqdTa5v5WIzbrk3vnyEs',
    authDomain: 'orion-c6095.firebaseapp.com',
    databaseURL: 'https://orion-c6095.firebaseio.com',
    projectId: 'orion-c6095',
    storageBucket: 'orion-c6095.appspot.com',
    messagingSenderId: '858264566406',
    appId: '1:858264566406:web:88f39b3456b4af2a'
  }
};
