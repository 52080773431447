import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
   selector: 'angly-about',
   templateUrl: './about.component.html',
   styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

   /* Variables */
   data: any;
   services = [];
   about: any;
   team = [];
   contact: any;
   mainImage: string;

   constructor(private pageTitleService: PageTitleService, private service: ChkService, private db: AngularFirestore) {

      /* Page title */
      this.pageTitleService.setTitle(' Know More About Us');

      /* Page subTitle */
      this.pageTitleService.setSubTitle(' Our latest news.');

      this.db.doc('pages/about').get().toPromise().then(about => {
         this.data = about.data();
         console.log(this.data);
      }).catch(err => {
         console.error(err.message);
      });

      this.db.collection('services').get().toPromise().then(types => {
         types.docs.forEach(type => {
            this.services.push(type.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.db.doc('settings/aboutusImage').get().toPromise().then(img => {
         this.mainImage = img.data().image;
      }).catch(err => {
         console.error(err.message);
      });

      this.service.getAbout().
         subscribe(response => { this.about = response },
            err => console.log(err),
            () => this.about
         );

      this.db.collection('collections').get().toPromise().then(teams => {
         teams.docs.forEach(team => {
            this.team.push(team.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.service.getContactContent().
         subscribe(response => { this.contact = response },
            err => console.log(err),
            () => this.contact
         );

   }


   ngOnInit() {
   }

}
