import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';
declare var $: any;

@Component({
   selector: 'angly-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

   /* Variables */
   homeContent: any;
   services = [];
   projects = [];
   projectGallary: any;
   posts = [];
   team = [];
   pricingContent: any;
   settingsTestimonials = false;
   contact: any;
   videoContent: any;
   mobileFeatured: any;
   testimonial = [];
   contactusImage: string;
   teamsOrder: any;

   constructor(private service: ChkService, private db: AngularFirestore, private cdRef: ChangeDetectorRef) {

      this.db.collection('services').get().toPromise().then(types => {
         types.docs.forEach(type => {
            this.services.push(type.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.db.collection('products').ref.limit(9).get().then(projects => {
         projects.docs.forEach(project => {
            this.projects.push(project.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.db.collection('blogs').ref.orderBy('create_date', 'desc').limit(3).get().then(blogs => {
         blogs.docs.forEach(blog => {
            this.posts.push(blog.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.db.doc('settings/teamsOrder').get().toPromise().then(order => {
         this.teamsOrder = order.data().order;
         const teamList = [];
         this.db.collection('collections').get().toPromise().then(teams => {
            teams.forEach(team => {
               teamList[team.data().id] = team.data();
            });
            this.teamsOrder.forEach(element => {
               const dp = teamList[element];
               this.team.push(dp);
               this.cdRef.detectChanges();
            });
         }).catch(err => {
            console.error(err.message);
         });
      }).catch(err => {
         console.error(err.message);
      });



      this.db.collection('testimonials').get().toPromise().then(testimonials => {
         testimonials.docs.forEach(testimonial => {
            this.testimonial.push(testimonial.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

      this.db.doc('settings/testimonials').get().toPromise().then(settings => {
         this.settingsTestimonials = settings.data().active;
      }).catch(err => {
         console.error(err.message);
      });

      this.db.doc('pages/contact').get().toPromise().then(contact => {
         this.contact = contact.data();
      }).catch(err => {
         console.error(err.message);
      });

      this.db.doc('settings/contactusImage').get().toPromise().then(img => {
         this.contactusImage = img.data().image;
      }).catch(err => {
         console.error(err.message);
      });

      this.service.getHomeContent().
         subscribe(response => { this.homeContent = response; },
            err => console.log(err),
            () => this.getContent(this.homeContent)
         );

      this.service.getPricingPageContent().
         subscribe(response => { this.pricingContent = response; },
            err => console.log(err),
            () => this.pricingContent
         );
   }

   subscribeFormClasses: any = { rowClass: 'row', fieldClass: 'col-sm-12 col-md-6' };

   ngOnInit() {
   }


   /*
    * getContent is used for get the home page content.
    * Used variables is videoContent and mobileFeatured.
    */
   getContent(content) {
      this.videoContent = content.video_content;
      this.mobileFeatured = content.mobile_featured;
   }



}
