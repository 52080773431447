import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'angly-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
    project: any;
    similarProjects: any;

    constructor(private db: AngularFirestore, private route: ActivatedRoute) {
        this.route.paramMap.subscribe(paramMap => {
            const id = paramMap.get('id');
            this.db.doc('products/' + id).get().toPromise().then(p => {
                this.project = p.data();
                this.db.collection('products').ref.where('typeId', '==', this.project.typeId).limit(3).get().then(sp => {
                    this.similarProjects = sp.docs;
                    console.log(this.similarProjects);
                }).catch(err => {
                    console.error(err.message);
                });
                console.log(this.project);
            }).catch(err => {
                console.error(err.message);
            });
        });
    }

    ngOnInit() {
    }

}
